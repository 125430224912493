<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <path
      fill="currentColor"
      d="M11 22c6.0751 0 11-4.9249 11-11 0-1.49685-.299-2.92388-.8405-4.22461-.4176.48769-.9671.85916-1.5931 1.0592.3645.98617.5636 2.05254.5636 3.16541 0 5.0424-4.0876 9.13-9.13 9.13-5.04236 0-9.13-4.0876-9.13-9.13 0-5.04236 4.08764-9.13 9.13-9.13 1.5752 0 3.0573.39892 4.3506 1.10125.2847-.58541.7279-1.07956 1.2736-1.42669C14.9785.563559 13.0551 0 11 0 4.92487 0 0 4.92487 0 11c0 6.0751 4.92487 11 11 11Z"
    />
    <path
      fill="currentColor"
      d="M10.9957 15c-.2653-.0001-.5197-.1143-.7073-.3174l-4.00179-4.3334c-.18479-.2038-.28779-.47831-.2866-.76389.00119-.28559.10649-.55911.29298-.76105.18649-.20195.43908-.31597.70281-.31726.26373-.0013.51727.11025.70544.31035l3.25636 3.52615 8.2985-9.98497c.1775-.21353.4261-.34196.691-.35704.265-.01507.5246.08444.7218.27664.1972.19221.3158.46137.3297.74827.0139.28689-.078.56803-.2554.78156l-9.004 10.83344c-.0909.1092-.2013.1974-.3246.259-.1233.0616-.2568.0955-.3925.0996h-.0264Z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'CheckmarkBoldIcon'
  }
</script>
